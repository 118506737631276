import { booleanAttribute, Component, EventEmitter, inject, input, Input, Output } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RoamButtonComponent } from "../button/roam-button/roam-button.component";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { GlobalFormStore } from "@app/shared/dialogs/global-form/+data-access/global-form.store";
import { SaveType } from "@app/shared/interfaces/save-type.model";
import { SaveProcess } from "@app/shared/interfaces/save-type.interface";

@Component({
  standalone: true,
  selector: "app-container-dialog-set",
  templateUrl: "./container-dialog-set.component.html",
  styleUrls: ["./container-dialog-set.component.scss"],
  imports: [MatProgressSpinnerModule, RoamButtonComponent, MatDialogModule]
})
export class ContainerDialogSetComponent {
  #dialogRef = inject(MatDialogRef<ContainerDialogSetComponent>);

  @Input()
  public isLoading!: boolean;
  readonly isValid = input(false, { transform: booleanAttribute });
  readonly buttonLabel = input("Save");
  readonly buttonLabelSaveAndContinue = input("Save & Continue");
  readonly hideButton = input<boolean>(false);

  protected globalStore = inject(GlobalFormStore);

  @Output()
  public onSave: EventEmitter<any> = new EventEmitter();

  @Output()
  public onClose: EventEmitter<Event> = new EventEmitter<Event>();

  onCancel(): void {
    if (this.globalStore.isDirty())
      return this.globalStore.confirmationDialog(this.#dialogRef, true, undefined, this.onClose);

    this.onClose.emit();
    this.#dialogRef.close();
  }
}
